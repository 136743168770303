/*header .glyphicon {
    width: 30px;
    height: 25px;
    font-size: 24px;
    margin: 0 0 0 25px;
}*/
/*header .widget-cart {
    height: 55px;
    position: relative;
    display: inline-block;
}
header .widget-cart:hover {
    text-decoration: none;
}
header .widget-cart > span {
    margin: 15px 0 0 0;
}
header .widget-cart .quantity {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    position: relative;
    top: -5px;
}*/
header .glyphicon-setting {
    margin: 0 0 0 15px;
    position: relative;
    top: 0;
}
/*header .setting {
    height: 55px;
}*/
/*header .setting ul {
    width: 180px;
    background: #FFFFFF;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 1px 5px 10px 0px rgba(50, 50, 50, 0.5);
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 1000;
    display: none;
}
header .setting ul li a {
    color: #000000;
    font-size: 16px;
    font-family: 'Muli';
    line-height: 40px;
    padding: 0 20px;
    display: block;
}
header .setting ul li a:hover {
    color: #FFFFFF;
    background: #00A8E2;
    text-decoration: none;
}
header .guest-setting {
    height: 55px;
}
header .guest-setting > div:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #FFFFFF transparent;
    content: " ";
    position: absolute;
    top: -10px;
    right: 7px;
}
header .guest-setting > div {
    width: 800px;
    background: #FFFFFF;
    list-style: none;
    margin: 0;
    padding: 20px;
    box-shadow: 1px 5px 10px 0px rgba(50, 50, 50, 0.5);
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 1000;
    display: none;
}
header .guest-setting > div > div:nth-child(1) {
    width: 291px;
    height: 326px;
    background: url('./images/guest-setting.png') no-repeat 0 0 transparent;
}
header .guest-setting > div > div:nth-child(2) {
    width: 420px;
    margin: 0 0 0 25px;
}
header .guest-setting > div > div:nth-child(2) p {
    font-size: 18px;
}*/