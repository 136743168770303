/*
    z-index rule
    Hover display: 1000
*/
@import url(http://fonts.googleapis.com/css?family=Muli:400,300,300italic,400italic);
body {
    font-family: 'Muli', sans-serif;
}
.font {
    font-family: 'Muli', sans-serif;
}
a {
    color: #00A8E2;
    outline: none;
}
a:hover {
    text-decoration: none;
}
a:hover,
a:focus,
a:visited,
a:active {
    outline: none;
    text-decoration: none;
}
@media print {
    a:link:after,
    a:visited:after {
        content: " ";
    }
}
.noscript {
    width: 500px;
    color: #888888;
    font-size: 18px;
    text-align: center;
    margin: 100px auto;
}
.loading {
    width: 100%;
    height: 300px;
    font-size: 24px;
    text-align: center;
    line-height: 300px;
    background: url('./images/icon-loading.gif') no-repeat center transparent;
    padding: 0;
}
.loading.small {
    height: 100px;
    font-size: 18px;
    line-height: 100px;
    padding: 0 0 0 150px;
}
.loading.tiny {
    line-height: 24px;
    padding: 5px 0 0 35px;
    background-size: 24px;
    display: inline;
}
.form-group {
    margin-bottom: 30px;
}
.question {
    width: 39px;
    height: 39px;
    background: url('./images/icon_question.png') no-repeat 0 0 transparent;
    display: block;
}
.return {
    width: 39px;
    height: 39px;
    background: url('./images/icon_return.png') no-repeat 0 0 transparent;
    display: block;
}
.logo_ups {
    width: 72px;
    height: 87px;
    background: url('./images/logo_ups.gif') no-repeat 0 0 transparent;
    display: block;
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}
.glyphicon-star-blue:before {
    color: #009BE4;
    content: "";
}
.glyphicon-star-gray:before {
    color: #808080;
    content: "";
}
.glyphicon-mail {
    background: url('./images/icon_mail.png') no-repeat center transparent;
}
.glyphicon-chat {
    background: url('./images/icon_chat.png') no-repeat center transparent;
}
.glyphicon-announcement {
    background: url('./images/icon_announcement.png') no-repeat center transparent;
}
.glyphicon-setting {
    background: url('./images/icon_setting.png') no-repeat center transparent;
}
.glyphicon-cart {
    background: url('./images/icon_cart.png') no-repeat center transparent;
}
/* Bootstrap hack */
.modal-dialog {
    margin: 100px auto;
}
.modal-header {
    font-size: 18px;
    font-weight: bold;
}
.modal-body {
    font-size: 18px;
    text-align: center;
}
.modal-body p {
    font-size: 13px;
    text-align: left;
    margin: 10px 20px;
}
.modal-footer {
    border: 0;
    text-align: center;
}
.modal-footer .btn {
    min-width: 150px;
    font-size: 30px;
    font-weight: bold;
}
.modal-footer .btn-primary,
.modal-footer .btn-primary:hover {
    background: #4D4D4D;
    border: #4D4D4D;
}
.modal-footer .btn-warning,
.modal-footer .btn-warning:hover {
    background: #FF5E1F;
    border: #FF5E1F;
}
ul.pagination > li > a {
    color: #888888;
    border: 0;
    padding: 0 5px;
}
ul.pagination > li > a:hover,
ul.pagination > li.active > a:hover,
ul.pagination > li.active > a:focus,
ul.pagination > li.active > a:visited,
ul.pagination > li.active > a:active,
ul.pagination > li.active > a {
    color: #009BE4;
    background: none;
    background-color: #FFFFFF;
}
ul.pagination > li:first-child > a,
ul.pagination > li:last-child > a {
    font-size: 48px;
    font-weight: lighter;
    line-height: 0.3;
}
a.btn,
button.btn {
    line-height: 1;
    padding: 3px 30px;
    box-shadow: 1px 2px 2px 0px rgba(50, 50, 50, 0.5);
}

a.btn1
{
    line-height: 1;
    padding: 3px 30px;
}

button.touchspin-down {
    line-height: 1;
    padding: 6px 13px;
    border-radius:0px;
    box-shadow: 0px 0px 0px 0px rgba(50, 50, 50, 0.5);
}

.btn-yellow {
    color:#000000;
    background: #FFD306;
    border-color:#FFD306;
}

.btn-yellow:hover,
.btn-yellow:focus,
.btn-yellow:active {
    color: #000000;
    background: #FFD306;
    border-color: #FFD306;
}

a.toTop {
    display: none;
    position: fixed;
    bottom: 110px;
    right: 10px;
    z-index: 99;
    padding: 12px;
    background-color: #fff;
}
a.toCart {
    position: fixed;
    bottom: 60px;
    right: 10px;
    z-index: 99;
    padding: 12px;
    background-color: #fff;
}

.btn-green {
    color:#000000;
    background: #7EDF3A;
    border-color:#7EDF3A;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active {
    color: #000000;
    background: #7EDF3A;
    border-color: #7EDF3A;
}

.btn-orange {
    color: #FFFFFF;
    background: #FF5E1F;
    border-color: #FF5E1F;
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active {
    color: #FFFFFF;
    background: #FF5E1F;
    border-color: #FF5E1F;
}

.btn-primary {
    color: #FFFFFF;
    background: #FF5E1F;
    border-color: #FF5E1F;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    color: #FFFFFF;
    background: #E5551C;
    border-color: #E5551C;
}
.btn-slave,
.btn-slave:hover {
    color: #FFFFFF;
    background: #4D4D4D;
    border-color: #4D4D4D;
}
.btn-blue,
.btn-blue:hover {
    color: #FFFFFF;
    background: #00A8E2;
    border-color: #00A8E2;
}
.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}
.pagination {
    margin: 0;
}

md-checkbox {
    margin: 0;
}
/* loading-bar */
#loading-bar-spinner {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    pointer-events: inherit;
    z-index: 1000;
}
#loading-bar-spinner .spinner-icon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border-width: 8px;
    margin-left: -30px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
}
/* ui-select */
.ui-select-container .btn-default.active,
.ui-select-container .btn-default.focus,
.ui-select-container .btn-default:active,
.ui-select-container .btn-default:focus,
.ui-select-container .btn-default:hover,
.ui-select-container .open > .dropdown-toggle.btn-default {
    background: none;
}
/* tooltip */
.tooltip.right .tooltip-arrow {
    border-right-color: #FFFFFF;
    box-shadow: 0 16px 10px -17px rgba(0,0,0,0.5);
}
.tooltip.right .tooltip-arrow:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -8px;
    left: 5px;
    box-shadow: -2px 3px 10px 0px rgba(0, 0, 0, 0.5);
    z-index: 99;
}
.tooltip-inner {
    max-width: 350px;
    min-height: 50px;
    color: #000000;
    background: #FFFFFF;
    box-shadow: 15px 10px 10px 5px rgba(0, 0, 0, 0.5);
    text-align: left;
    padding: 12px;
    opacity: 1;
    position: relative;
    left: 6px;
    top: 7px;
    z-index: 100;
}
/* footer */
