.checkout-confirm-redirect .modal-body {
    text-align: left;
}
.checkout-modal .btn {
    width: 255px;
    height: 60px;
}
.item-notice {
    margin: 0 0 20px 40px;
    padding: 0;
}
.item-notice ul {
    list-style: none;
}
.item-notice li {
    font-size: 12px;
    line-height: 1;
}
.order-checkout {
    padding: 20px 30px;
}
.order-checkout > h1 {
    color: #010101;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.8;
    margin: 0;
}
.order-checkout > h2 {
    color: #010101;
    font-size: 18px;
    font-weight: normal;
    line-height: 3;
    margin: 0;
}
.order-checkout > p {
    padding: 0 5px;
}
.order-checkout > section {
    padding: 10px 5px 20px;
}
.order-checkout > hr {
    margin: 0;
}
.order-checkout .table thead tr th {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: normal;
    background: #808080;
    border-color: #808080;
}
.order-checkout .table .product_id {
    color: #808080;
    font-size: 12px;
}
.order-checkout > ul {
    list-style: none;
}
.order-checkout > section > div > label {
    width: 160px;
}
.order-note {
    width: 350px;
}
.order-summary {
    background: #CCCCCC;
    border-radius: 10px;
    padding: 15px;
}
.order-summary > div {
    width: 550px;
    height: 180px;
    background: #FFFFFF;
    border-radius: 10px;
}
.order-summary > div > div {
    margin: 12px 0;
}
.order-summary > div > div:nth-child(1) {
    width: 235px;
    height: 100px;
    font-size: 18px;
    border-right: 1px solid #CCCCCC;
    text-align: center;
}
.order-summary > div > div:nth-child(2) {
    width: 315px;
}
.order-summary > div > div:nth-child(2) label {
    width: 125px;
    color: #000000;
    font-size: 16px;
    text-align: right;
    margin: 0 5px 0 0;
}
.order-summary > div > div:nth-child(2) i {
    width: 12px;
    text-align: center;
    display: inline-block;
}
.order-summary > div > div:nth-child(2) span {
    color: #010101;
    font-size: 18px;
}
.order-summary > div > div:nth-child(2) label + span {
    padding: 0 0 0 15px;
}
.order-summary > div > div:nth-child(2) div:last-child {
    margin: 15px 0 0;
}
.order-summary > div > div:nth-child(2) div:last-child span {
    color: #00A8E2;
}
.order-shipping > div {
    margin: 0 0 15px 0;
}
.order-shipping > div > label {
    width: 180px;
    color: #4C4C4C;
    font-size: 14px;
    font-weight: normal;
}
.order-shipping > div > span {
    min-width: 120px;
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px 0 0;
    display: inline-block;
}
.order-links {
    text-align: right;
    margin: 20px 0 0;
}
.order-links a {
    min-width: 216px;
    height: 60px;
    font-size: 30px;
    font-weight: bold;
    line-height: 60px;
    padding: 0 20px;
    margin: 0 40px 0 0;
}
.main-menu.ups ul {
    list-style: none;
}
.main-view.form.ups select:after {
    width: 20px;
    height: 30px;
    content: " ";
    background: #F00;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}
.main-view.form.ups div.head h1 {
    width: 300px;
    font-size: 20px;
    line-height: 90px;
    padding: 0 0 0 100px;
}
.main-view.form.ups div.head button.md-button {
    font-size: 16px;
    margin: 30px 0 0;
    padding: 0 30px;
}
.main-view.form.ups h3 {
    font-size: 16px;
}
.main-view.form.ups label {
    font-size: 14px;
}
.main-view.form.ups button {
    height: 50px;
    font-size: 20px;
    font-weight: bold;
}
.main-view.form.ups .input-weight input[type="text"] {
    padding-left: 50px;
    padding-right: 50px;
}
.main-view.form.ups .input-dimension input[type="text"] {
    padding-left: 50px;
    padding-right: 50px;
}
.main-view.form.ups .input-weight:after {
    color: #CCCCCC;
    content: "kg";
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 1;
}
.main-view.form.ups .input-dimension:after {
    color: #CCCCCC;
    content: "cm";
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 1;
}
.rate-modal .modal-dialog {
    margin: 30px auto;
}
.rate-modal .modal-body {
    min-height: 120px;
    max-height: 450px;
    padding: 20px 30px;
    overflow: auto;
}
.rate-modal .modal-body > div {
    text-align: left;
}
.rate-modal .modal-body .loading {
    color: #4C4C4C;
    font-size: 14px;
    line-height: 100px;
}
.rate-modal .modal-body h3 {
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0 0 0;
}
.rate-modal .modal-body hr {
    margin: 5px 0;
}
.rate-modal .modal-body div label {
    width: 180px;
    color: #4C4C4C;
    font-size: 14px;
    font-weight: normal;
    text-align: right;
    margin: 0 5px 0 0;
}
.rate-modal .modal-body div span {
    color: #808080;
    font-size: 14px;
    font-weight: normal;
}
.rate-modal .modal-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.lightbox-modal .modal-dialog {
    margin-top: 50px;
}
.lightbox-modal .modal-body {
    padding: 0;
    position: relative;
}
.lightbox-modal .lightbox-nav {
    position: static;
}
.lightbox-modal .lightbox-nav a {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    color: #666;
    cursor: pointer;
    font-size: 26px;
    padding: 5px;
    position: absolute;
    top: 50%;
    z-index: 999;
}
.lightbox-modal .lightbox-nav a:nth-child(1) {
    left: 0;
}
.lightbox-modal .lightbox-nav a:nth-child(2) {
    right: 0;
}
.lightbox-modal .lightbox-image-container {
    /*margin: 25px;*/
}
.lightbox-modal .lightbox-image-container img {
    /*width: 100% !important;*/
}
.lightbox-modal .lightbox-image-container iframe {
    width: 90% !important;
    height: 400px;
}
.lightbox-modal .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
}
.form-horizontal .control-label.require-field:before {
    color: #FF0000;
    font-size: 18px;
    line-height: initial;
    content: "*";
    margin: 0 10px 0 0;
    position: relative;
    top: 3px;
}
.form-horizontal .control-label {
    text-align: left;
}
.form-horizontal .control-label:before {
    min-width: 10px;
    color: #FF0000;
    font-size: 18px;
    line-height: initial;
    content: " ";
    margin: 0 10px 0 0;
    position: relative;
    top: 3px;
    display: inline-block;
}
.contact-window .modal-dialog {
    margin: 10px auto;
}
.contact-modal .modal-header {
    font-size: 24px;
    border: 0;
    padding-bottom: 0;
}
.contact-modal .modal-body {
    padding: 20px;
}
.contact-modal .modal-body .alert{
    text-align: left;
}
.contact-modal textarea {
    height: 200px;
    resize: none;
}
.main-view.Logs table tr td {
    position: relative;
}
.main-view.Logs table tr td div {

    position: absolute;
    top: 0;
    left: 0;
}
.main-view.Logs table tr td div ul {
    list-style: none;
    margin: 0;
    padding: 0;
}







/* moved start */
.product {
    padding: 20px;
}
.product > div:nth-child(1) {
    width: 200px;
    margin: 0 20px 0 0;
}
.product > div:nth-child(2) {
    width: 760px;
    position: relative;
}
.product > div:nth-child(1) > div {
    margin: 5px 0 0 0;
}
.product > div:nth-child(1) > div > label {
    margin: 0;
}
.product > div:nth-child(2) h3 {
    font-size: 18px;
    font-weight: 900;
    margin: 10px 0 0 0;
}
.product > div:nth-child(2) p {
    color: #4C4C4C;
    font-size: 14px;
    font-weight: normal;
    margin: 10px 0 20px 0;
}
.product > div:nth-child(2) hr {
    border-color: #CCCCCC;
    margin: 15px 0 10px;
}
.product > div > div.product-id {
    font-size: 14px;
    font-weight: normal;
}
.product > div > div.product-photo {
    margin: 0 0 20px 0;
}
.product .product-photo > img {
    width: 200px;
    height: 200px;
}
.product .product-reviews {
    margin: 10px 0 0 0;
}
.product .product-reviews span i {
    font-size: 18px;
}
.product .product-reviews span:last-child {
    color: #808080;
    font-size: 14px;
    line-height: 1;
    margin: 0 0 0 5px;
}
.product .product-information {
    margin: 0;
}
.product .product-information > div > label {
    width: 130px;
    text-align: right;
    margin: 0 10px 0 0;
}
.product .product-components {
    margin: 20px 0 0 0;
}
.product .product-components ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}
.product .product-components ul li {

}
.product .product-components ul li a {
    color: #000000;
    position: relative;
}
.product .product-components ul li a span.product-components-photo {
    background: url('./images/icon_camera.png') no-repeat right center transparent;
    padding: 0 20px 0 0;
}
.product .product-components ul li a div {
    width: 420px;
    padding: 10px;
    border: 1px solid #CCCCCC;
    background: #FFFFFF;
    position: absolute;
    z-index: 10000;
    right: -440px;
    top: -210px;
    display: none;
}
.product .product-components ul li a div:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #CCCCCC transparent transparent;
    content: " ";
    position: absolute;
    top: 204px;
    left: -15px;
}
.product .product-components ul li a div:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #FFFFFF transparent transparent;
    content: " ";
    position: absolute;
    top: 204px;
    left: -13px;
}
.product .product-components ul li a:hover {
    color: #000000;
}
.product .product-components ul li a:hover div {
    display: block;
}
.product .product-components ul li a div img {
    width: 400px;
    height: 400px;
}
.product .product-price > label {
    text-align: right;
    display: block;
}
.product .product-stock {
    text-align: right;
}
.product .product-stock .available {

}
.product .product-stock .unavailable {
    color: #FF0000;
}
.product .product-qty {
    margin: 10px 0 0;
}
.product .product-qty > label {
    width: 200px;
    margin: 0;
}
.product .product-qty select {
    width: auto;
    display: inline-block;
}
.product .product-screenshot > div {
    width: 760px;
    margin: 15px 0 0;
    padding: 0 0 15px 0;
    white-space: nowrap;
    overflow: auto;
    position: relative;
}
.product .product-screenshot span img {
    height: 200px;
    margin: 0 10px 0 0;
    cursor: pointer;
}
.product .product-screenshot span.video {
    position: relative;
}
.product .product-screenshot span.video img {
    border: 3px solid #000000;
}
.product .product-screenshot span.video:after {
    width: 100px;
    height: 100px;
    background: url('./images/icon_video_play.png') no-repeat center center transparent;
    content: " ";
    display: block;
    position: absolute;
    top: -50px;
    left: 80px;
    cursor: pointer;
}
.product .suggestive {
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
}
.product div.guest-billing_plan-tip {
    width: 500px;
    background: #FFFFFF;
    list-style: none;
    margin: 5px 0 0;
    padding: 20px;
    box-shadow: 0px 0px 20px 3px rgba(50, 50, 50, 0.5);
    position: absolute;
    right: -20px;
    z-index: 1000;
}
.product div.guest-billing_plan-tip:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #FFFFFF transparent;
    content: " ";
    position: absolute;
    top: -10px;
    right: 94px;
}
.product .add-to-cart {
    margin: 10px 0 0;
}
.product .add-to-cart button {
    width: 260px;
    height: 60px;
    font-size: 30px;
    font-weight: bold;
}
.product .product-specification h4 {
    font-size: 16px;
    font-weight: bold; 
}
.product .product-specification > div > p {
    margin-left: 20px;
}
.product .product-support h4 {
    font-size: 16px;
    font-weight: bold; 
}
.product .product-faqs h4 {
    font-size: 16px;
    font-weight: bold; 
}
.product .product-attachment {
    position: relative;
}
.product .product-attachment h4 {
    font-size: 16px;
    font-weight: bold; 
}
.product .product-attachment ul {
    list-style: url('./images/icon_download.png') inside;
    padding: 0;
    margin: 10px 0 0 0;
}
.product .product-attachment ul li {
    margin: 5px 0 0 0;
}
.product .product-attachment > div.guest-download-tip {
    width: 500px;
    background: #FFFFFF;
    list-style: none;
    margin: 0;
    padding: 20px;
    box-shadow: 0px 0px 20px 3px rgba(50, 50, 50, 0.5);
    position: absolute;
    bottom: -225px;
    left: -20px;
    z-index: 1000;
}
.product .product-attachment > div.guest-download-tip:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #FFFFFF transparent;
    content: " ";
    position: absolute;
    top: -10px;
    left: 20px;
}
.product .product-faqs > ul {
    list-style: none;
    margin: 10px 0 0 30px;
    padding: 0;
}
.product .product-faqs > ul > li {
    margin: 20px 0 0 0;
}
.product .product-faqs > ul > li > div {
    margin: 10px 0 0;
}
.product .product-faqs > ul > li > div > * {
    margin: 0;
    display: inline-block;
}
.product .product-faqs > ul > li > div > i,
.product .product-faqs > ul > li > div > b {
    font-weight: normal;
    margin: 0 10px 0 0;
}
.product .product-faqs > ul > li > div > label {
    font-weight: normal;
}
.product .product-alsobuy {
    margin: 20px 0 0;
}
.product .product-alsobuy > div {
    width: 760px;
    margin: 20px 0 0;
    padding: 0 0 20px 0;
    white-space: nowrap;
    overflow: auto;
}
.product .product-alsobuy > div > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.product .product-alsobuy > div > ul li {
    width: 229px;
    height: 229px;
    margin: 0 20px 0 0;
    /*background: #E6E6E6;*/
    display: inline-block;
}
.product .product-alsobuy > div > ul li a {
    position: relative;
    display: block;
}
.product .product-alsobuy > div > ul li h3 {
    width: 100%;
    color: #FFFFFF;
    background: rgba(50, 50, 50, .6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    padding: 0 0 0 5px;
    line-height: 1.5;
    position: absolute;
    bottom: 0;
}
.product .product-alsobuy > div > ul li img {
    width: 229px;
    height: 229px;
}
.product .product-alsobuy > div > ul li .reviews {
    padding: 5px 10px 10px;
}
.product .product-alsobuy > div > ul li .reviews div:first-child span {
    color: #00A8E2;
}
.product .product-alsobuy > div > ul li .reviews div:last-child span {
    color: #808080;
    font-size: 14px;
}
.product .product-alsobuy > div label {
    padding: 10px;
}
.product .product-alsobuy > div > a:last-child {
    margin: 0;
}
.product .product-alsobuy > div > a > div > img {
    width: 220px;
    height: 120px;
}
.product .product-alsobuy > div > a > h3 {
    width: 100%;
    color: #FFFFFF;
    background: rgba(50, 50, 50, .6);
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    padding: 5px 10px;
    margin: 0;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}
.product .product-alsobuy > div > a > label {
    color: #808080;
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    padding: 5px 10px;
    margin: 0;
    cursor: pointer;
}
.product .product-alsobuy > div > a > hr {
    border-color: #CCCCCC;
    margin: 10px 12px 5px;
}
.product .product-reviews {
    margin: 20px 0 0 0;
}
.product .product-reviews ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
}
.product .product-reviews ul li div:nth-child(1) span:nth-child(1) {
    font-size: 14px;
    margin: 0 5px 0 0;
}
.product .product-reviews ul li div:nth-child(1) span:nth-child(2) {
    font-size: 14px;
    background: url('./images/icon_person.png') no-repeat left center transparent;
    padding: 0 0 0 25px;
}
.product .product-reviews ul li div:nth-child(1) span:nth-child(3) {
    color: #808080;
    font-size: 14px;
    font-weight: normal;
}
.product .product-reviews ul li div:nth-child(2) {
    margin: 5px 0 0 0;
}
.product .product-reviews ul li div:nth-child(3) a {
    text-decoration: underline;
}
.product .product-reviews ul li div:nth-child(4) p {
    margin: 5px 0;
}
.product .product-reviews ul li div:nth-child(5) > a {
    text-decoration: underline;
}
.product-billing-plan h4 {
    margin: 0;
}
.product-billing-plan .table tbody tr td {
    height: 40px;
    vertical-align: middle;
}
.product-billing-plan .table tbody tr td i {
    color:#00A8E2;
    font-style: normal;
}
.product-billing-plan .tip {
    width: 16px;
    height: 16px;
    background: url('./images/icon_info.png') no-repeat center center transparent;
    display: inline-block;
}
.product-first-time-charge {
    margin: 0;
    padding: 0;
    list-style: none;
}
.product-order-quantity {
    padding: 5px;
    background: #808080;
}
.product-order-quantity select {
    width: auto;
    height: 30px;
    padding: 0 12px;
    margin: 0 0 20px;
    display: inline-block;
}
.product-order-quantity > div {
    margin: 20px 0 0 50px;
}
.product-order-quantity > div > div label {
    width: 150px;
    font-weight: bold;
    margin: 0;
}
.product-order-quantity > div > div span {
    min-width: 120px;
    display: inline-block;
}
.product-additional {
    padding: 5px;
    margin: 1px 0 0 0;
    background: #BFBFBF;
}
.product-additional ul {
    list-style: none;
    margin: 0 0 0 10px;
    padding: 0;
}
.product-additional ul li {
    margin: 20px 0;
}
.product-additional ul li label {
    margin: 0 20px 0 0;
    display: inline-block;
}
.product-additional ul li label span {
    padding: 0 0 0 30px;
}
.product-additional ul li div > span {
    min-width: 120px;
    display: inline-block;
}
.product-additional select {
    width: auto;
    height: 30px;
    padding: 0 12px;
    display: inline-block;
}
.product-quotation > div > div {
    margin: 10px 0 0;
}
.product-quotation > div > div > label {
    width: 150px;
    font-weight: bold;
    margin: 0;
}
.product-add-to-cart {
    margin: 30px 0 0;
    text-align: right;
}
.product-add-to-cart a.btn {
    width: 230px;
    font-size: 24px;
    font-weight: bold;
    line-height: 50px;
    margin: 0 0 0 15px;
}
/* moved end */